import {
    GET_BAGDONATIONS,
    ADD_BAGDONATION,
    EDIT_BAGDONATION,
    GET_BAGDONATION,
    RESET_BAGDONATION,
    BAGDONATIONS_ERROR,
} from "../types/bagdonation_type";

const initialState = {
    bagdonations: null,
    bagdonation: null,
    total_bagdonations: 0,
    loading: true,
    error: {},
    bagdonation_message: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_BAGDONATIONS:
            return {
                ...state,
                bagdonations: payload,
                loading: false,
                error: {},
            };

        case RESET_BAGDONATION:
            return {
                ...state,
                bagdonation: null,
                loading: true,
                error: {},
            };

        case ADD_BAGDONATION:
            return {
                ...state,
                bagdonation_message: payload,
                loading: false,
                error: {},
            };
        case GET_BAGDONATION:
            return {
                ...state,
                bagdonation: payload,
                loading: false,
                error: {},
            };
        case EDIT_BAGDONATION:
            return {
                ...state,
                bagdonation_message: payload,
                loading: false,
                error: {},
            };

        case BAGDONATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
