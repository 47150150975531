import React from 'react'

function YoutubeVideoCard({ yt_link }) {
    return (
        <div>
            <div className="col-md-4">
                <iframe width="360" height="215" src={yt_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default YoutubeVideoCard