import React, { useState } from "react";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Slider from "react-slick";
import AwardCard from "../cards/AwardCard";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextInput } from "../../components/Form/TextInput"
import { addNewsletter } from "../../store/actions/newsletters_action"


function Newsletter({ addNewsletter, loading }) {
    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const [isLoading, setIsLoading] = useState(false);
    const [showGreeting, setSetshowGreeting] = useState(false)

    return (
        <>
            {!loading ? (
                <section className="what-people ptb-50 bg-F1F1F1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sec-heading">
                                    <h2>NEWSLETTER</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-7">
                                <div className="testimonial-content">
                                    <h3>Subscribe to our newsletter today!</h3>

                                    <p>We are very grateful for all our donors, volunteers and employers and we want to share with you what we have been able to do with their support.</p>

                                    <p>Who is supporting us in the mainstream and supported employment programs. Read about the corporate engagement, sensitization workshops and the exposure visits for our students.</p>

                                    <p>It’s been an exciting quarter for National NGO Foundation and we want you to read all about it in our Newsletter. What’s even more exciting is that every quarter, we will have a refreshed newsletter! Subscribe Below</p>
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="testimonial-form">
                                    <Formik
                                        initialValues={{
                                            email: "",
                                        }}
                                        validationSchema={Yup.object({
                                            email: Yup.string().required("Required"),
                                        })}
                                        onSubmit={async (
                                            values,
                                            { setSubmitting, resetForm }
                                        ) => {
                                            setSubmitting(true);
                                            await addNewsletter(values);
                                            resetForm();
                                            setSubmitting(false);
                                        }}
                                    >
                                        {(formik) => {
                                            console.log(formik);
                                            return (
                                                <Form>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <TextInput
                                                                label="Email"
                                                                name="email"
                                                                type="text"
                                                                placeholder="Enter Your Email "
                                                            />
                                                        </div>
                                                        <div className="donate-button">
                                                            <button onClick={() => setSetshowGreeting(!showGreeting)} className="btn btn-donate" type="submit">
                                                                {isLoading ? "Processing..." : "Submit Now"}
                                                            </button>
                                                        </div>

                                                    </div>
                                                    {showGreeting ? (
                                                        <div className="testimonial-greeting align-self-center donate-button">
                                                            <h3>Thank you!!</h3>
                                                            <p>Thank you For Subscribing Our Newsletter.</p>
                                                        </div>
                                                    ) : (null)}

                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <div>
                    <Spinner />
                </div>
            )}
        </>
    );
}


const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addNewsletter };

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
