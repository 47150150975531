import { db } from "../../shared/config";
import {
    collection,
    addDoc,
    getDocs,
    getDoc,
    doc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    startAfter,
    endBefore,
    limitToLast,
    limit,
    Timestamp,
    where,
} from "firebase/firestore";
import {
    GET_BLOODDONATIONS,
    ADD_BLOODDONATION,
    GET_BLOODDONATION,
    RESET_BLOODDONATION,
    BLOODDONATIONS_ERROR,
} from "../types/blooddonation_type";

import { setAlert } from "./alert";
import { LIMIT } from "../../domain/constant";
//GET BLOGS
const blooddonationsRef = collection(db, "blooddonations");
export const getBlooddonations =
    ({ term, termField, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                if (term) {
                    searchQuery = query(blooddonationsRef, where(termField, "==", term));
                } else {
                    searchQuery = query(
                        blooddonationsRef,
                        orderBy(orderValue, orderType),
                        limit(LIMIT)
                    );
                }
                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_BLOODDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: BLOODDONATIONS_ERROR,
                });
            }
        };

export const getNextBlooddonations =
    ({ term, termField, last, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                console.log("TERM", term);
                if (term) {
                    searchQuery = query(
                        blooddonationsRef,
                        orderBy(orderValue, orderType),
                        startAfter(last[orderValue]),
                        limit(LIMIT),
                        where(termField, "==", term)
                    );
                } else {
                    searchQuery = query(
                        blooddonationsRef,
                        orderBy(orderValue, orderType),
                        startAfter(last[orderValue]),
                        limit(LIMIT)
                    );
                }
                console.log("searchQuery", searchQuery);

                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_BLOODDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: BLOODDONATIONS_ERROR,
                });
            }
        };
export const getPreviousBlooddonations =
    ({ term, termField, first, orderValue = "createdAt", orderType = "desc" }) =>
        async (dispatch) => {
            try {
                let searchQuery = null;
                if (term) {
                    searchQuery = query(
                        blooddonationsRef,
                        orderBy(orderValue, orderType),
                        endBefore(first[orderValue]),
                        limitToLast(LIMIT),
                        where(termField, "==", term)
                    );
                } else {
                    searchQuery = query(
                        blooddonationsRef,
                        orderBy(orderValue, orderType),
                        endBefore(first[orderValue]),
                        limitToLast(LIMIT)
                    );
                }

                const querySnapshot = await getDocs(searchQuery);
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                dispatch({
                    type: GET_BLOODDONATIONS,
                    payload: data,
                });
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something went wrong", "danger"));
                dispatch({
                    type: BLOODDONATIONS_ERROR,
                });
            }
        };

export const addblooddonation = (data) => async (dispatch) => {
    try {
        data.createdAt = Timestamp.fromDate(new Date());
        const docRef = await addDoc(blooddonationsRef, data);
        dispatch({
            type: ADD_BLOODDONATION,
        });
        dispatch(setAlert("Blooddonation added successfully", "success"));
    } catch (e) {
        console.error("Error adding document: ", e);
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: BLOODDONATIONS_ERROR,
        });
    }
};

export const getblooddonation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESET_BLOODDONATION,
        });
        const docRef = doc(db, "blooddonations", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            data.id = id;
            dispatch({
                type: GET_BLOODDONATION,
                payload: data,
            });
        } else {
            dispatch(setAlert("Something went wrong", "danger"));
            dispatch({
                type: BLOODDONATIONS_ERROR,
            });
        }
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: BLOODDONATIONS_ERROR,
        });
    }
};
export const editBlooddonation = (id, data) => async (dispatch) => {
    try {
        console.log("Data", data);
        const docSnap = await updateDoc(doc(db, "blooddonations", id), data);
        dispatch(setAlert("Blooddonation updated successfully", "success"));
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: BLOODDONATIONS_ERROR,
        });
    }
};

export const deleteBlooddonation = (id) => async (dispatch) => {
    try {
        const docSnap = await deleteDoc(doc(db, "blooddonations", id));
        dispatch(setAlert("Blooddonation deleted successfully", "success"));
    } catch (err) {
        dispatch(setAlert("Something went wrong", "danger"));
        dispatch({
            type: BLOODDONATIONS_ERROR,
        });
    }
};
