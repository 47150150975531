import {
    GET_BLOODDONATIONS,
    ADD_BLOODDONATION,
    EDIT_BLOODDONATION,
    GET_BLOODDONATION,
    RESET_BLOODDONATION,
    BLOODDONATIONS_ERROR,
} from "../types/blooddonation_type";

const initialState = {
    blooddonations: null,
    blooddonation: null,
    total_blooddonations: 0,
    loading: true,
    error: {},
    blooddonation_message: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_BLOODDONATIONS:
            return {
                ...state,
                blogs: payload,
                loading: false,
                error: {},
            };

        case RESET_BLOODDONATION:
            return {
                ...state,
                blooddonation: null,
                loading: true,
                error: {},
            };

        case ADD_BLOODDONATION:
            return {
                ...state,
                blooddonation_message: payload,
                loading: false,
                error: {},
            };
        case GET_BLOODDONATION:
            return {
                ...state,
                blooddonation: payload,
                loading: false,
                error: {},
            };
        case EDIT_BLOODDONATION:
            return {
                ...state,
                blooddonation_message: payload,
                loading: false,
                error: {},
            };

        case BLOODDONATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
