import React, { useEffect, useState } from 'react'
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { TextArea, TextInput, SelectBox } from "../../components/Form/Form";
import { addSpecialdonation } from "../../store/actions/specialdonations_action";
import { DonationTypeData } from '../../models/donationtype';
import BreadCrumb from '../../components/template/BreadCrumb';

function SingleDonationType({ match, addSpecialdonation }) {
    const [isLoading, setIsLoading] = useState(false);

    console.log("Slug mai kya hai-", match.params.slug);
    const [donationypeData, setDonationypeData] = useState(DonationTypeData)




    return (
        <div>
            <Header active="DONATION" />
            <BreadCrumb title={`Donate Now`} />
            <section className='blood-donation'>
                <div className="container">
                    {donationypeData && donationypeData.map((item) => {
                        if (item.slug == match.params.slug) {
                            return (
                                <div>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="single-donation-view">
                                                <div className="single-card-img">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-title">
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                    <div className="card-para">
                                                        <p>{item.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="contact-heading blood-donation-box-shadow">
                                                <h4>{item.title} Form</h4>
                                                <div className="contact-form">
                                                    <Formik
                                                        initialValues={{
                                                            products_in_numbers: "",
                                                            name: "",
                                                            email: "",
                                                            phone: "",
                                                            message: "",
                                                        }}
                                                        validationSchema={Yup.object({
                                                            name: Yup.string().required("Required"),
                                                            email: Yup.string().required("Required"),
                                                            phone: Yup.string().required("Required"),
                                                        })}
                                                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                            setSubmitting(true);
                                                            values.product = item.title
                                                            await addSpecialdonation(values);
                                                            resetForm();
                                                            setSubmitting(false);


                                                        }}
                                                    >
                                                        {(formik) => {
                                                            console.log(formik);
                                                            return (
                                                                <Form>
                                                                    <div className="row">

                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    placeholder="Quantity in Numbers"
                                                                                    name="products_in_numbers"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.products_in_numbers}
                                                                                />
                                                                                {formik.errors && formik.errors.donor_name && (
                                                                                    <p className="text-danger">{formik.errors.donor_name}</p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Name"
                                                                                    name="name"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.name}
                                                                                />
                                                                                {formik.errors && formik.errors.donor_name && (
                                                                                    <p className="text-danger">{formik.errors.donor_name}</p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    className="form-control"
                                                                                    placeholder="Email"
                                                                                    name="email"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.email}
                                                                                />
                                                                                {formik.errors && formik.errors.email && (
                                                                                    <p className="text-danger">{formik.errors.email}</p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Contact Number"
                                                                                    name="phone"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.phone}
                                                                                />
                                                                                {formik.errors && formik.errors.phone && (
                                                                                    <p className="text-danger">{formik.errors.phone}</p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    rows={3}
                                                                                    placeholder="Notes (If Any)"
                                                                                    name="message"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.message}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="donate-button">
                                                                            <button className="btn btn-donate" type="submit">
                                                                                {
                                                                                    // isLoading ? "Processing..." : "Submit Now"
                                                                                }
                                                                                Submit
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            );
                                                        }}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="contact-solid blood-donation-box-shadow br-3px">
                                                <div className="text-left quote">
                                                    <i className="fa fa-quote-left" />
                                                </div>
                                                <div className="text-center quote-c">
                                                    <h3>
                                                        A man's true wealth is the good he does in this world.
                                                    </h3>
                                                </div>
                                                <div className="text-right quote">
                                                    <i className="fa fa-quote-right" />
                                                </div>
                                                <div className="start">
                                                    <h3>Just A Little Help Can Make Their Hearts Happier!</h3>
                                                </div>
                                                <div className="donate-btn text-center mt-5">
                                                    <Link
                                                        className="btn btn-donate my-2 my-sm-0"
                                                        to="/donate-now"
                                                        style={{ padding: "12px 50px" }}
                                                        type="submit"
                                                    >
                                                        Donate Now
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        else return (
                            null
                        )
                    })

                    }

                </div>
            </section>
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addSpecialdonation };

export default connect(mapStateToProps, mapDispatchToProps)(SingleDonationType);

