import React, { useState } from 'react'
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { TextArea, TextInput, SelectBox } from "../../components/Form/Form";
import { addbagdonation } from "../../store/actions/bagdonation_action";
import BreadCrumb from '../../components/template/BreadCrumb';
import { DonationTypeData } from '../../models/donationtype';


function DonationType() {
    const [isLoading, setIsLoading] = useState(false);
    const [donationypeData, setDonationypeData] = useState(DonationTypeData)

    return (
        <div>
            <Header active="Donations" />
            <BreadCrumb title={"Donate Now"} />
            <section className='blood-donation ptb-30'>
                <div className="container">
                    <div className="row">
                        {
                            donationypeData && donationypeData.map((item) => {
                                return (
                                    <>
                                        <div className="col-md-4">
                                            <div className="donation-type-card">
                                                <div className="card-img">
                                                    <img src={item.img} alt="" />
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-title">
                                                        <h4>{item.title}</h4>
                                                    </div>
                                                    <div className="card-para">
                                                        <p>{item.short_desc}</p>
                                                    </div>
                                                </div>
                                                <div className="card-donate-now-btn">
                                                    <div className="donate-btn text-center mb-5 mt-2">
                                                        <Link
                                                            className="btn btn-donate my-2 my-sm-0"
                                                            // to="/donate-now"
                                                            to={`/donation-types/${item.slug}`}
                                                            style={{ padding: "12px 50px" }}
                                                            type="submit"
                                                        >
                                                            Donate Now
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default DonationType