export const YoutubeLink = [
    {
        yt_link: "https://www.youtube.com/embed/REOWAEMwtjc?si=Z9gyJXd5i7MncBaC"
    },
    {
        yt_link: "https://www.youtube.com/embed/mzaE3Sz0qCY?si=En96p0WesFHwROkl"
    },
    {
        yt_link: "https://www.youtube.com/embed/OuEd30umg50?si=itSpCHrgQ8ZLaS7F"
    },
    {
        yt_link: "https://www.youtube.com/embed/r9pSqJ84sCQ?si=LqVJ90QwMelRTB-z"
    },
    {
        yt_link: "https://www.youtube.com/embed/1pQjXcI1EJ0?si=-RGFhFySdZAfl8Ol"
    },
    {
        yt_link: "https://www.youtube.com/embed/-wnj9_M4ipw?si=IOUKMAocSE3BiXTq"
    },
    {
        yt_link: "https://www.youtube.com/embed/w3-NG2sRY-c?si=6_79ZsvkXJ6bJSGQ"
    },
    {
        yt_link: "https://www.youtube.com/embed/qtUraTbC7Ek?si=8pIKN47F0by63e-j"
    },
    {
        yt_link: "https://www.youtube.com/embed/_dAQnP2J3BM?si=Xwuc6Q7uU4fHZ9u0"
    },
]