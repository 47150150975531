export const DonationTypeData = [
    {
        title: "Donate Sewing Machine",
        img: "/assets/images/96227.jpg",
        short_desc: "Join our mission to empower and uplift communities through the gift of sewing machines. ",
        desc: "Join our mission to empower individuals and communities through the gift of sewing. Your sewing machine donation can make a significant impact by providing the tools needed for skill development, income generation, and self-sufficiency. Help us stitch together a brighter future, one donation at a time.",
        slug: "donate-sewing-machine",
    },
    {
        title: "Donate Stationary",
        img: "/assets/images/donation-box-with-school-supplies-old-wooden-table.jpg",
        short_desc: "Join us in our mission to promote education and empower the next generation. Donate stationery items now.",
        desc: "Join our mission to empower underserved communities by supporting our Stationary Donation Drive. Your contribution helps provide essential educational tools like notebooks, pens, and art supplies to children in need, ensuring they have the resources to pursue their dreams and education. Make a lasting impact today.",
        slug: "donate-stationary",
    },
    {
        title: "Donate Bag",
        img: "/assets/images/school-bag-donation.png",
        short_desc: "Support Education with School Bag Donations! Help children in need access quality education by contributing school bags to our NGO.",
        desc: "Support Education with School Bag Donations! Help children in need access quality education by contributing school bags to our NGO. Your donation will empower these young minds to carry their dreams and aspirations with pride, ensuring they have the tools they need to succeed in school and beyond. Join us in making a difference – one school bag at a time.",
        slug: "donate-bag",
    },
]