import React from "react";
import { connect } from "react-redux";
import Header from "../../components/template/Header";
import Footer from "../../components/template/Footer";
import BreadCrumb from "../../components/template/BreadCrumb";
import { Link } from "react-router-dom";
import Statics from "../../components/template/Statics";
const ThankYouSpecialDonation = (props) => {
    return (
        <div>
            <Header active="ABOUT" />
            <BreadCrumb title={"Thank You"} />
            <section className="who-we ptb-50">
                <div className="container">
                    <div className="who-we-are">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Greetings, </h3>
                                <p>
                                    Thank you for getting in touch with us & submitting all the
                                    details. We really appreciate it.
                                </p>
                                <p>Kind Regards,</p>
                                <p>
                                    {" "}
                                    <strong>
                                        {" "}
                                        Team National Organization For Social Empowerment.{" "}
                                    </strong>{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouSpecialDonation);
