import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/template/Header';
import Footer from '../../components/template/Footer';
import DonationForm from '../../components/donation/DonationForm';
import { getCalamitieBySlug } from '../../store/actions/calamities_action';
import { useEffect } from 'react';
const DonateCalam = ({
  getCalamitieBySlug,
  match,
  calamitie: { calamitie },
}) => {
  useEffect(() => {
    if (match.params.slug) {
      getCalamitieBySlug(match.params.slug);
    }
  }, [match.params.slug]);
  return (
    <div>
      <Header active='CONTACT_US' />
      <section className='main-cause pb-50' style={{ paddingTop: '30px' }}>
        <div className='container'>
          <div className='stic'>
            <div className='row'>
              <div className='col-md-7'>
                <div className='donation-form'>
                  <h3 className='details'>Donation Details</h3>
                  <DonationForm />
                  <hr />
                  {/* <div className="bank-details">
                    <h3>
                      Our Bank details to make donation from net-banking /
                      Internet Banking.
                    </h3>
                    <div className="main-details">
                      <h3>State Bank of India Details</h3>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Beneficiary Name</div>
                        <div className="value">
                          National Organisation for Social Empowerment
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Types Of Account</div>
                        <div className="value">Current</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Account Number</div>
                        <div className="value">32344893165</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch</div>
                        <div className="value">
                          Sagar Tower, District Centre, Janakpuri, New Delhi-
                          110058
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch Code</div>
                        <div className="value">001706</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">IFSC Code</div>
                        <div className="value">SBIN0001706</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">MICR Code</div>
                        <div className="value">110002054</div>
                      </div>
                    </div>
                    <hr />
                    <div className="main-details">
                      <h3>ICICI Bank Details</h3>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Beneficiary Name</div>
                        <div className="value">
                          National Organisation for Social Empowerment
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Types Of Account</div>
                        <div className="value">Current</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Account Number</div>
                        <div className="value">032205001782</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch</div>
                        <div className="value">
                          SAFDARJUNG ENCLAVE, NEW DELHI 110029
                        </div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">Branch Code</div>
                        <div className="value">000322</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">IFSC Code</div>
                        <div className="value">ICIC0000322</div>
                      </div>
                      <hr />
                      <div className="account-flex">
                        <div className="name">MICR Code</div>
                        <div className="value">110229044</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='col-md-5'>
                <div
                  className='sidebar'
                  style={{ position: 'sticky', top: '10px' }}
                >
                  <h3>You Are Donation Towards</h3>
                  {calamitie && (
                    <div className='banner-ei'>
                      <div className='main-image'>
                        <img
                          src={
                            calamitie.featured_image
                              ? calamitie.featured_image
                              : '/assets/images/22811264.jpg'
                          }
                          style={{
                            height: '400px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                      <div id='home' className='main-content'>
                        <div className='#home'>
                          <h2>{calamitie.name}</h2>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ calamitie: state.calamitie });

const mapDispatchToProps = { getCalamitieBySlug };

export default connect(mapStateToProps, mapDispatchToProps)(DonateCalam);
