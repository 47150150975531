import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function OurGoal() {
    return (
        <>
            <section className="who-we ptb-50 our-goal-sec">
                <div className="container">
                    <div className="who-we-are our-goal">
                        <div className="row">
                            <div className="col-md-9 mx-auto">
                                <div className="our-goal-content">
                                    <div className="our-goal-title">
                                        <h3>OUR GOALS</h3>
                                    </div>
                                    <div className="our-goal-bullet-pointers">
                                        <ul>
                                            <li>
                                                <p>To facilitate access to basic healthcare for every section of society</p>
                                            </li>
                                            <li>
                                                <p>To ensure food security for the needy</p>
                                            </li>
                                            <li>
                                                <p>To enable the rehabilitation of persons with disabilities (PWDs) through supplemental education, extensive training, and providing assistive aids designed for daily living, education, and the workplace.</p>
                                            </li>
                                            <li>
                                                <p>To empower the disabled and differently abled by helping them become capable of employment, which will help them become economically independent.</p>
                                            </li>
                                            <li>
                                                <p>To build the next generation of people with disabilities who are confident, assertive, smart, and independent.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="hungry">
                                    <Link className="btn btn-donate-lg" to="/projects/national-ngo-kitchen" >FEED A HUNGRY CHILD</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurGoal