export const LittleAngels = [
    {
        name: "Mishti Rajora",
        age: "9 Years",
        featured_img: "/assets/images/01.png",
        medical_doc: "",
        disease: "Acute Promyelocytic Leukemia (APML)",
        short_desc: "In a heartbreaking turn of events, we got the news that Mishti Rajora, the brave 9-year-old battling Acute Promyelocytic Leukemia (APML), is no longer with us.",
        desc: "Mishti Rajora-Forever in our hearts In a heartbreaking turn of events, we got the news that Mishti Rajora, the brave 9-year-old battling Acute Promyelocytic Leukemia (APML), is no longer with us. Despite her father, Arvind Kumar's relentless efforts and the support sought from the National NGO, Mishti's valiant fight against this devastating disease has come to an end. Arvind Kumar, an unemployed father, faced insurmountable challenges in arranging funds for his daughter's treatment, but the National NGO stepped in with a helping hand. Our hearts go out to the Rajora family during this difficult time, and we extend our deepest condolences for the loss of their precious bundle of joy. May Mishti rest in peace, and may the family find strength in the memories they shared."
    }
]